import { inject, Injectable, WritableSignal } from '@angular/core';
import { child, Database, ListenEvent, objectVal, onValue, ref, remove as removeInDB, set, stateChanges } from "@angular/fire/database";
import { traceUntilFirst } from "@angular/fire/performance";
import { ref as storageRef } from "@angular/fire/storage";
import { AbstractControl } from "@angular/forms";
import { uploadBytes, UploadResult } from "@firebase/storage";
import { FirebaseStorage } from "firebase/storage";
import { mergeMap } from "rxjs/operators";
import { ToastrService } from '../../../shared/application/services/toastr.service';
import { JobMessageInterface } from "../../domain/models/job-message.interface";
import { DateUtil } from '../utils/date/date.util';
import { v5 as uuidv5 } from 'uuid';


export enum ExportStatusEnum
{
    pending = 'pending',
    processing = 'processing',
    done = 'done',
    failed = 'failed',
}

export type ExportStatusType = keyof typeof ExportStatusEnum;

export interface Notification
{
    jobId: string;
    exportName: string;
    status: ExportStatusType;
    createdAt: number;
    startedAt?: number;
    updatedAt?: number;
    paths: string[];
    info?: Record<string, any>;
    loading?: boolean;
}

@Injectable({
    providedIn: 'root',
})

export class ExportService
{
    readonly LIMIT_ACTIVE_EXPORTS = 10;
    readonly LIFE_DAYS = 1;
    notificationsCount: number = 0;
    notificationsMap: Record<string, Notification> = {};


    static readonly EXPORT_CHANNEL = 'exports';
    static readonly SHARED_LOCKS_CHANNEL = 'sharedLocks';
    static readonly EXPORT_TYPE = 'export';

    static readonly EXPORT_STATUS_PENDING = ExportStatusEnum.pending as 'pending';
    static readonly EXPORT_STATUS_PROCESSING = ExportStatusEnum.processing as 'processing';
    static readonly EXPORT_STATUS_DONE = ExportStatusEnum.done as 'done';
    static readonly EXPORT_STATUS_FAILED = ExportStatusEnum.failed as 'failed';

    static readonly EXPORT_STATUSES = [
        ExportService.EXPORT_STATUS_PENDING,
        ExportService.EXPORT_STATUS_PROCESSING,
        ExportService.EXPORT_STATUS_DONE,
        ExportService.EXPORT_STATUS_FAILED,
    ] as ExportStatusType[];

    static readonly FINAL_STATUSES = [
        ExportService.EXPORT_STATUS_DONE,
        ExportService.EXPORT_STATUS_FAILED,
    ] as ExportStatusType[];
    uid: string;
    private db: Database = inject(Database);

    constructor(public toastrService: ToastrService)
    {
    }

    static validateFile = (
        file: File,
        MAX_FILE_SIZE: number = 500
    ): string | null => {
        const fileTypes = [
            'text/csv',                                                             // CSV
            'application/vnd.ms-excel',                                             // XLS
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',    // XLSX
            // 'application/vnd.oasis.opendocument.spreadsheet',                    // ODS
        ];
        const fileTypeRegex = new RegExp(fileTypes.join('|'), 'gi');

        switch (true) {
            case file.size > MAX_FILE_SIZE * 1024 * 1024:
                return `File size exceeds ${MAX_FILE_SIZE} MB`;
            case !fileTypeRegex.test(file.type):
                return 'You can upload file using XLS, XLSX or CSV file';
            default:
                return null;
        }
    }

    static async saveFile(
        control: AbstractControl,
        importFile: File,
        loading: WritableSignal<boolean>,
        filePath: string,
        boodmoFileImportsBucket: FirebaseStorage,
    ): Promise<UploadResult | void>
    {
        let errorMsg: string | null = null;

        const setError = (msg: string) => {
            control.setErrors({customValidation: msg}, {emitEvent: true});
        }

        errorMsg = ExportService.validateFile(importFile);
        if (errorMsg) return setError(errorMsg);

        const newName = importFile.name.split('.');
        const ext = newName.pop();
        const standardizeName = `${uuidv5([
            ...newName,
            Date.now()
        ].join('.'), uuidv5.DNS)}.${ext}`;
        const file = new File([importFile], standardizeName, {
            type: importFile.type,
            lastModified: importFile.lastModified,
        });

        const _storageRef = storageRef(boodmoFileImportsBucket, `${filePath}${file.name}`);
        try {
            loading.set(true);
            return await uploadBytes(_storageRef, file);
        } catch (e) {
            setError(e?.message ?? 'Error while uploading file. Please try again later.');
        } finally {
            loading.set(false);
        }
    }

    init()
    {
        onValue(this.dbRef, async (snapshot) => {
            this.notificationsMap = snapshot.val() || {};

            // Automatically remove notifications older than 1 day
            for (const notification of this.allExportResults) {
                if (notification.status === ExportStatusEnum.done && DateUtil.datesComparison(
                    'before',
                    DateUtil.addDays(new Date(notification.createdAt * 1000), this.LIFE_DAYS),
                    new Date()
                )) {
                    await this.remove(notification);
                }
            }
            this.notificationsCount = Object.keys(this.notificationsMap).length
        })
    }

    get dbRef()
    {
        return ref(this.db, `/${ExportService.EXPORT_CHANNEL}/${this.uid}/`);
    }

    get itemsCount(): number
    {
        return this.notificationsCount;
    }

    get notifications(): Notification[]
    {
        return Object.values(this.notificationsMap)
    }

    get allExportResults(): Notification[]
    {
        try {
            return [
                ...this.notifications.map((
                    {
                        createdAt,
                        startedAt,
                        updatedAt,
                        ...notification
                    }) => ({
                    ...notification,
                    createdAt: createdAt * 1000,
                    startedAt: startedAt * 1000,
                    updatedAt: updatedAt * 1000,
                    isNotification: true,
                })),
            ];
        } catch (e) {
            console.log('Error while parsing localStorage Export results', e);
            return [];
        }
    }

    get hasDone(): boolean
    {
        return this.notifications.some(item => item.status === ExportStatusEnum.done);
    }

    get canAdd(): boolean
    {
        return this.itemsCount < this.LIMIT_ACTIVE_EXPORTS;
    }

    preCheck = () => {
        if (!this.canAdd) {
            this.toastrService.errorNotify(`You can't add more than ${this.LIMIT_ACTIVE_EXPORTS} Exports`);
            throw new Error(`You can't add more than ${this.LIMIT_ACTIVE_EXPORTS} Exports`);
        }
    }

    addNotification = async ({jobId, exportName}: {exportName: string; jobId: string;}, useNotify = true) =>
    {
        this.preCheck();

        stateChanges(child(this.dbRef, jobId), {
            events: [ListenEvent.changed],
        }).subscribe(({snapshot}) => {
            if (snapshot.key !== 'status') return;
            const status = snapshot.val();
            if (useNotify && status === ExportStatusEnum.done)
                this.toastrService.successNotify(`${exportName} was successfully finished and ready to download`);
        })

        const notificationRef = child(this.dbRef, jobId);
        const notify$ = objectVal(notificationRef).pipe(
            traceUntilFirst('db'),
            mergeMap((notification): Promise<void> => {
                if (!notification) return set(child(this.dbRef, jobId), {
                    jobId,
                    exportName,
                    status: ExportStatusEnum.pending,
                    createdAt: Math.floor(Date.now() / 1000),
                    paths: [],
                });
                return Promise.resolve();
            }
        )).subscribe(() => {
            notify$.unsubscribe();
        });
    }

    async addNotificationWithInfo({jobId, type, exportName}: JobMessageInterface & { exportName: string })
    {
        if (type !== ExportService.EXPORT_TYPE) return Promise.reject('Invalid type');
        await this.addNotification({jobId, exportName});
        this.toastrService.successNotify('Export successfully added to queue.');
    }

    async remove(item: Notification)
    {
        item.loading = true;
        try {
            await this.removeNotification(item.jobId);
        } finally {
            item.loading = false;
        }
    }

    private removeNotification = (key: string) => removeInDB(child(this.dbRef, key));
}
